import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

const InspirationPage = () => {
  return (
    <Layout>
      <h1 className="mobile-header lg:dektop-header">Inspiration</h1>
      <div className="flex flex-col lg:flex-row items-center pt-4">
        <div className="mx-auto">
          <h2 className="mobile-inspiration-quote mx-4 xl:mx-0 lg:max-w-sm lg:desktop-quote">
            “When the world becomes a massive mess, with nobody at the helm,
            It’s time for Artists to make their mark”
          </h2>
          <p className="mobile-body lg:desktop-body py-4">Joni Mitchell</p>
        </div>
        <StaticImage
          width={816}
          src="../images/inspiration/02.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0 lg:ml-auto"
        />
      </div>
      <div className="flex flex-wrap gap-4 mt-6 justify-evenly">
        <StaticImage
          height={399}
          src="../images/inspiration/carolina.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />
        <StaticImage
          height={399}
          width={640}
          src="../images/inspiration/IMG_8114.jpg"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <StaticImage
          height={399}
          width={309}
          src="../images/inspiration/th.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />

        <StaticImage
          height={399}
          width={309}
          src="../images/inspiration/th-3.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />
        <StaticImage
          height={399}
          width={640}
          src="../images/inspiration/mail(1).png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
        />
        <StaticImage
          height={399}
          width={309}
          src="../images/inspiration/Nichiren.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <div className="mx-auto my-auto lg:hidden">
          <h2 className="mobile-inspiration-quote mx-4 xl:mx-0 max-w-md my-4 lg:max-w-xl lg:desktop-quote">
            “If you have a voice within you say “Yo cannot paint!” Then by all
            means, Paint, and the voice will disappear”
          </h2>
          <p className="mobile-body lg:desktop-body py-4">Vincent Van Gogh</p>
        </div>

        <StaticImage
          height={515}
          width={477}
          src="../images/inspiration/60723.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <StaticImage
          height={515}
          width={800}
          src="../images/inspiration/mackay.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />

        <StaticImage
          height={399}
          width={309}
          src="../images/inspiration/vedic.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />
        <StaticImage
          height={399}
          width={640}
          src="../images/inspiration/th-1.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <div className="lg:hidden">
          <StaticImage
            width={309}
            src="../images/inspiration/summer.png"
            alt="Bob's art"
            placeholder="tracedSVG"
            className="flex-shrink-0 "
            layout="fixed"
          />
        </div>
        <StaticImage
          height={399}
          width={309}
          src="../images/inspiration/image2.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />

        <StaticImage
          height={515}
          width={477}
          src="../images/inspiration/cactus.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <StaticImage
          height={515}
          width={800}
          src="../images/inspiration/IMG_5972.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />

        <StaticImage
          height={515}
          width={477}
          src="../images/inspiration/th-2.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <div className="mx-auto my-auto ">
          <h2 className="mobile-inspiration-quote mx-4 xl:mx-0 max-w-md my-4 lg:max-w-xl lg:desktop-quote">
            “Creativity is intelligence having fun”
          </h2>
          <p className="mobile-body lg:desktop-body py-4">Albert Einstein</p>
        </div>
        <div className="lg:hidden">
          <StaticImage
            width={343}
            src="../images/inspiration/tabla.png"
            alt="Bob's art"
            placeholder="tracedSVG"
            layout="fixed"
          />
        </div>

        <StaticImage
          height={515}
          width={477}
          src="../images/inspiration/IMG_1399.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
        <StaticImage
          height={515}
          width={800}
          src="../images/inspiration/th-4.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          className=""
          layout="fixed"
        />
      </div>
    </Layout>
  )
}

export default InspirationPage
